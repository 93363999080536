import React, { useRef, useState } from "react"
import EmploymentHistory from "../components/EmploymentHistory"
import HealthAndMedicalQuestionnaire from "../components/HealthAndMedicalQuestionnaire"
import Layout from "../components/layout"
import PersonalDetails from "../components/PersonalDetails"
import SButton from "../components/button"
import Signature from "../components/Signature"
import { Formik } from "formik"
import Form from "../components/form/FormHelper"
import { Helmet } from "react-helmet"
import { LCard, Col2 } from "../style/containers"
import { initialValues } from "../components/IndustrialApplication/InitialValues"
import { Error } from "../components/form"
import { submitForm, handleSubmitResponse, FormatFormData, dateCheck } from "../services"
import { validationSchema } from "../components/IndustrialApplication/Validation"
import {
  BankDetails,
  ContractOfEmployment,
  CriminalRecord,
  Education,
  EqualOpportunitiesMonitoring,
  IDCard,
  PrivacyNotice,
  TeamLifting,
  WorkingTimeDirective,
  PreviousEmployment,
} from "../components/IndustrialApplication"
import { FormikInput } from "../components/form"
import References from "../components/References"
import { Documents } from "../components/fileUpload"

const IndustrialApplication = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const createCandidate = values => {
    return (
      values.personal_details.title +
      ". " +
      values.personal_details.first_name +
      " " +
      values.personal_details.surname
    )
  }

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      const formatData = {
        ...values,
        personal_details: {
          ...values.personal_details,
          date_of_birth: dateCheck(values.personal_details?.date_of_birth),
        },
        education: {
          ...values.education,
          start_date: dateCheck(values.education?.start_date),
          end_date: dateCheck(values.education?.end_date),
        },
        employment_history: values.employment_history.map(emp => {
          return {
            ...emp,
            date_from: dateCheck(emp.date_from),
            date_to: dateCheck(emp.date_to)
          }
        }),
        criminal_record: {
          ...values.criminal_record,
          security_clearance_granted: dateCheck(values.criminal_record.security_clearance_granted),
          security_clearance_expires: dateCheck(values.criminal_record.security_clearance_expires),
        },
      }
      const formData = await FormatFormData(formatData, sigCanvas)
      let res = await submitForm(formData, "/industrial")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Industrial Application Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            setFieldValue,
            values,
            setFieldTouched,
            validateForm,
            isSubmitting,
          }) => (
            <Form>
              <h1>Industrial Application Form</h1>
              <Col2>
                <FormikInput
                  name="consultant_name"
                  type="text"
                  label="Consultant name"
                />
              </Col2>
              <h2>Personal details</h2>
              <PersonalDetails values={values} setFieldValue={setFieldValue} />
              <Documents />
              <h2>Bank details</h2>
              <BankDetails />
              <h2>ID Card</h2>
              <IDCard values={values} setFieldValue={setFieldValue} />
              <h2>Education</h2>
              <Education values={values} setFieldValue={setFieldValue} />
              <h2>Employment History</h2>
              <EmploymentHistory
                values={values}
                validateForm={validateForm}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
              />
              <h2>References</h2>
              <References
                values={values}
                validateForm={validateForm}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
              />
              <h2>Health and Medical Questionnaire</h2>
              <HealthAndMedicalQuestionnaire
                values={values}
                setFieldValue={setFieldValue}
              />
              <h2>Equal Opportunities Monitoring</h2>
              <EqualOpportunitiesMonitoring
                values={values}
                setFieldValue={setFieldValue}
              />
              <h2>Team Lifting</h2>
              <TeamLifting />
              <h2>Working Time Directive</h2>
              <WorkingTimeDirective
                values={values}
                createCandidate={createCandidate}
              />
              <h2>Criminal Record</h2>
              <CriminalRecord values={values} setFieldValue={setFieldValue} />
              <h2>Previous Employment Sheet</h2>
              <PreviousEmployment
                values={values}
                setFieldValue={setFieldValue}
              />
              <h2>Privacy Notice</h2>
              <PrivacyNotice />
              {/* Contract removed on Terry's request 19/10/2020, still has the last section */}
              {/* <h2>Contract of Employment</h2> */}
              <ContractOfEmployment
                values={values}
                createCandidate={createCandidate}
              />
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                  <Error style={{ marginTop: "1rem" }} key={e}>
                    {e}
                  </Error>
                ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default IndustrialApplication
